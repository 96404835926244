import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Parse from 'parse';
import { IonicVue } from '@ionic/vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import plausible from './plugins/plausible';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';

const plausibleOptions = {
  enableAutoPageviews: true,
  enableAutoOutboundTracking: true,
  domain: "tour.guidasa.de",
  apiHost: "https://insights.guidasa.de/",
  trackLocalhost: true,
}

const app = createApp(App)
  .use(IonicVue)
  .use(router).use(plausible, plausibleOptions)

Parse.serverURL = 'https://parseapi.back4app.com/';
Parse.initialize("4knM9eyEu6IMEMx4kCq7rdXiVv3uk3Nxv3YcHy8f", "Mkx8W4LzxPyMVz8GN2vvp3j5Wr0Rj18ok9QzI8b1");
router.isReady().then(() => {
  app.mount('#app');
});
