import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TourPage from "@/views/TourPage.vue";
import ErrorPage from "@/views/ErrorPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/tour/:id',
    name: 'Tour',
    component: TourPage
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
