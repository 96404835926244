<template>
  <div :id="tour.id" @click="$emit('click')">
        <ion-accordion-group color="transparent" value="properties">
          <ion-accordion class="bg-transparent" value="properties" @click="track('click-accordion', 'Eigenschaften')">
            <ion-item slot="header">
              <ion-label>
                Toureneigenschaften
              </ion-label>
            </ion-item>
            <div slot="content" class="grid grid-cols-2 p-2 gap-4">
              <div
                v-for="prop in Object.keys(tour.get('properties'))"
                :key="prop"
                class="flex flex-col justify-center items-center"
              >
                <span class="text-desc text-md text-xs">{{propTrans[prop]}}</span>
                <span class="text-content text-md text-xl font-bold -mt-1">{{ tour.get('properties')[prop] }} {{propsUnit[prop]}}</span>
              </div>
            </div>
          </ion-accordion>
          <ion-accordion class="bg-transparent" value="packinglist" @click="track('click-accordion', 'Packliste')">
            <ion-item slot="header">
              <ion-label>
                Packliste
              </ion-label>
            </ion-item>
            <div slot="content" class="packing-list p-2">
              <div v-for="item in packingList"
                   :key="item">
                <ion-chip
                    @click="trackPackingList(item.get('translations').de.name)"
                    :id="item.id"
                    class="mb-2 text-center flex"
                    color="dark"
                >
                  <span class="w-full text-center font-bold">{{item.get('translations').de.name}}</span> <ion-icon :icon="informationCircle" />

                </ion-chip>
                <ion-popover :trigger="item.id" trigger-action="click">
                  <ion-content class="ion-padding">{{item.get('translations').de.desc}}</ion-content>
                </ion-popover>
              </div>



            </div>
          </ion-accordion>
          <ion-accordion class="bg-transparent" value="directions" @click="track('click-accordion', 'Anfahrt')">
            <ion-item slot="header">
              <ion-label>
                Anfahrt
              </ion-label>
            </ion-item>
            <div slot="content" class="flex flex-col p-2">
              <a class="text-blue-400 underline ml-5" :href="googleMapsLink" target="_blank">
                  In Karten-App öffnen
              </a>
            </div>
          </ion-accordion>
          <ion-accordion class="bg-transparent" value="maps" @click="track('click-accordion', 'Karte')">
            <ion-item slot="header">
              <ion-label>
                Karte
              </ion-label>
            </ion-item>
            <div slot="content" class="flex flex-col p-2">
              <div id="tour-detail-map"></div>
            </div>
          </ion-accordion>
          <ion-accordion class="bg-transparent" value="description" @click="track('click-accordion', 'Beschreibung')">
            <ion-item  slot="header">
              <ion-label class="text-desc">
                Kurzbeschreibung
              </ion-label>
            </ion-item>
            <ion-item slot="content">
              <ion-text class="text-md text-content p-2">
                {{ tour.get('translations')['de'].desc }}
              </ion-text>
            </ion-item>
          </ion-accordion>
          <ion-accordion class="bg-transparent" value="feedback" @click="track('click-accordion', 'Beschreibung')">
            <ion-item  slot="header">
              <ion-label class="text-desc">
                Gib uns Feedback
              </ion-label>
            </ion-item>
            <ion-item slot="content" >
              <div class="flex flex-col">
                <ion-text class="text-md text-content p-2">
                  Unterstützen Sie uns dabei, unsere Tourenplanung zu optimieren und jedem das ideale Erlebnis zu bieten. Nehmen Sie an unserer Umfrage teil und teilen Sie uns Ihre Meinung mit.
                </ion-text>
                <ion-button mode="ios" color="warning" class="feedback-button text-white mb-10" @click="onFeedback">
                  Hier geht es zur Umfrage
                </ion-button>
              </div>

            </ion-item>
          </ion-accordion>



        </ion-accordion-group>
    <div class="flex flex-row overflow-x-auto p-2 md:hidden">
      <img v-for="img in imagesTour" loading="lazy" class="aspect-video w-[90%] rounded-lg mr-5 object-cover" :key="img" @click="$emit('imgClick', img)" :src="img" />
    </div>
  </div>
</template>

<script>
import 'mapbox-gl/dist/mapbox-gl.css';
import { heart, add, images, informationCircle } from 'ionicons/icons';
import {
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
    IonIcon,
  IonText,
  IonContent,
    IonPopover,
    IonChip
} from '@ionic/vue';
import mapboxgl from 'mapbox-gl';
export default {
  name: 'TourDetailComponent',
  components: {
    IonAccordionGroup,
    IonAccordion,
    IonItem,
    IonLabel,
    IonChip,
    IonIcon,
    IonPopover,
    IonText,
    IonContent
  },
  props: {
    tour: {
      type: Object,
      required: true,
    },
    imagesTour: {
      type: Array,
      required: false
    },
    packingList: {
      type: Array,
      required: false,
    }
  },
  data() {
    return {
      heart,
      add,
      images,
      informationCircle,
      loading: null,
      accessToken:
        'pk.eyJ1IjoiZ3VpZGF0ZWFtIiwiYSI6ImNrdDAxemFmMTF2cHgzMXB1emczamY2OXEifQ.34FQtZ-JnREVC3OFBf1EPg',
      mapStyle: 'mapbox://styles/mapbox/outdoors-v11',
      map: null,
      mapMarker: {},
      mapControls: {},
      mapRoutes: {},
      show: false,
      propTrans: {
        up: 'Aufstieg',
        down: 'Abstieg',
        distance: 'Distanz',
        duration: 'Dauer',
      },
      propsUnit: {
        up: 'm',
        down: 'm',
        distance: 'km',
        duration: 'h',
      }
    };
  },
  computed: {
    googleMapsLink() {
      const ua = navigator.userAgent.toLowerCase();
      const isAndroid = ua.indexOf("android") > -1;
      const firstLong = this.tour.get('locations')[0].longitude;
      const firstLat = this.tour.get('locations')[0].latitude;
      if(isAndroid) {
        return `https://www.google.com/maps/search/?api=1&query=${firstLat},${firstLong}`
      }
      else {
        return `http://maps.apple.com/?ll=${firstLat},${firstLong}&q=Startpunkt: ${this.tour.get('translations')['de'].name}`;
      }

    },

    appleMapsLink() {
      const firstLong = this.tour.get('locations')[0].longitude;
      const firstLat = this.tour.get('locations')[0].latitude;
      return 'http://maps.apple.com/maps?q=' + firstLat + ',' + firstLong;
    },
  },
  async mounted() {
    mapboxgl.accessToken =
      'pk.eyJ1IjoiZ3VpZGF0ZWFtIiwiYSI6ImNrdDAxemFmMTF2cHgzMXB1emczamY2OXEifQ.34FQtZ-JnREVC3OFBf1EPg';
    await this.loadMap();

    this.map.on('load', () => {
      console.log('load');
      this.addFullscreenControl();
      this.addSource('route', this.getCoordinates(this.tour.get('locations')));
      this.addGeolocateControl();
    });
  },
  methods: {
    track(name, props) {
      this.$trackEvent(name, {props: {data: props}});
    },
    trackPackingList(id){
      this.$trackEvent('click-packing-list', {props: {id}});
    },
    onFeedback() {
      window.open('https://www.survio.com/survey/d/A5U5E8V3B9W9G1G9I', '_blank')
    },
    onHeart() {
      this.$emit('onHeart');
    },
    onAdd() {
      this.$emit('add');
    },
    onImages() {
      this.$emit('showImages');
    },
    async loadMap() {
      console.log('loadMap');
      this.map = await new mapboxgl.Map({
        container: 'tour-detail-map', // container ID
        style: 'mapbox://styles/mapbox/outdoors-v11', // style URL
        center: [this.tour.get('locations')[0].longitude, this.tour.get('locations')[0].latitude], // starting position [lng, lat]
        zoom: 9, // starting zoom
      });
      this.map.on('render', () => {
        this.map.resize();
      });
    },
    addFullscreenControl() {
      this.map.addControl(new mapboxgl.FullscreenControl());
    },
    addGeolocateControl() {
      try {
        this.map.addControl(
          new mapboxgl.GeolocateControl({ showUserHeading: true, trackUserLocation: true })
        );
      } catch (e) {
        console.log(e);
      }
    },
    addMarker(name, position, options) {
      this.mapMarker[name] = new mapboxgl.Marker(options).setLngLat(position).addTo(this.map);
    },
    addSource(id, coordinates) {
      console.log('addSource');
      const finishMarker = document.createElement('div');
      finishMarker.classList.add('finish-marker');
      this.addMarker('startMarker', coordinates[0], { anchor: 'bottom', element: finishMarker });
      this.addMarker('endMarker', coordinates[coordinates.length - 1]);
      console.log(coordinates);
      this.map.addSource(id, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates,
          },
        },
      });
      this.map.addLayer({
        id,
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#294965',
          'line-width': 5,
        },
      });
    },
    getCoordinates(data) {
      if (data[0]) {
        return data.map((el) => {
          return [el.longitude, el.latitude];
        });
      } else {
        return [data.longitude, data.latitude];
      }
    },
  },
};
</script>

<style scoped>
.action-circle {
  @apply rounded-full h-16 w-16 shadow flex justify-center items-center;
}
.action-buttons {
  @apply absolute w-full;
  bottom: -2em;
  z-index: 50;
}

.heart:active {
  animation-name: grow;
  animation-duration: 3s;
}
@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(3);
  }
}
#tour-detail-map {
  @apply rounded-lg;
  min-height: 300px;
  width: 100%;
}

ion-item{
  --background: transparent;
}

.packing-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.feedback-button {
  --padding-bottom: 1rem;
  --padding-top: 1rem;
}
</style>
